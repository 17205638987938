import request from '@/utils/http'

// 租户查询
export function queryList(params) {
  return request({
    url: '/tenant/findByPage',
    method: 'post',
    data: params
  })
}

// 查询所有有效的租户
export function queryAll() {
  return request({
    url: '/tenant/listAll',
    method: 'post',
    data: {
      approveStatus: 0,
      status: 0
    }
  })
}

/***
 * 租户修改
 */
export function tenantEdit(params) {
  return request({
    url: '/tenant/edit',
    method: 'post',
    data: params
  })
}

/***
 * 租户新增
 */
 export function tenantSave(params) {
  return request({
    url: '/tenant/save',
    method: 'post',
    data: params
  })
}

/***
 * 查询客户经理列表
 */
export function getSellerList(params) {
  return request({
    url: '/user/queryByName',
    method: 'get',
    params
  })
}


/***
 * 冻结/解冻
 */
 export function status(params) {
  return request({
    url: '/tenant/batchStatus',
    method: 'get',
    params
  })
}

/***
 * 批量冻结/解冻
 */
 export function batchStatus(params) {
  return request({
    url: '/tenant/batchStatus',
    method: 'get',
    params
  })
}



/***
 * 批量认证
 */
 export function batchVerify(params) {
  return request({
    url: '/tenant/batchVerify',
    method: 'get',
    params
  })
}


/***
 * 认证
 */
 export function verify(params) {
  return request({
    url: '/tenant/verify',
    method: 'get',
    params
  })
}





