// 获取该租户的工地列表
import request from "@/utils/http";

export function getAllRecyleProjectList(params) {
    return request({
        url: '/recycleProject/pc/Alllist',
        method: 'get',
        data: params
    })
}
