import request from '@/utils/http'

// 查询设备信息
export function queryList(params) {
  return request({
    url: '/device/findByPage',
    method: 'get',
    params
  })
}

// 修改设备信息
export function deviceEdit(params) {
  return request({
    url: '/device/edit',
    method: 'post',
    data: params
  })
}

// 新增设备
export function deviceSave(params) {
  return request({
    url: '/device/save',
    method: 'post',
    data: params
  })
}

// 编辑设备状态
export function updateStatus(params) {
  return request({
    url: '/device/status',
    method: 'get',
    params
  })
}

// 编辑设备状态
export function clearBind(params) {
  return request({
    url: '/device/clearBind',
    method: 'get',
    params
  })
}

export function projectDevices(params) {
  return request({
    url: '/device/projectDevices',
    method: 'get',
    params
  })
}

